import React, {Fragment} from 'react';
import Content from './Content';
import OnlyLogoNavbar from '../../components/Navbar/OnlyLogoNavbar.js';

const PrivacyPolicy = (props) => {
  return(
    <Fragment>
      <OnlyLogoNavbar/>
      <div className="editm-section">
        <Content/>    
      </div>
    </Fragment>
  );
}

export default PrivacyPolicy;