const axios = require('axios');

const user = {
  getEmail() {
    return localStorage.getItem('editmLoggedUserEmail');
  },
  getLicense() {
    return localStorage.getItem('editmUserLicenseKey');
  },
  async setLicense(licenseKey) {
    let userEmail = user.getEmail();

    let response = await axios.post('https://editmode-api.herokuapp.com/license', {
      userEmail,
      licenseKey
    });

    localStorage.setItem('editmUserLicenseKey', licenseKey);
    
    return response;
  }
}

export default user;