import node from './node.js';

const dom = {
  html: null,
  init() {
    dom.html = document.querySelector('html');
    dom.handleRelativeDependencies();
    document.addEventListener('click', dom.preventDefaults );
  },
  preventDefaults(e) {
    if(!node.isEditModeElement(e.target)) e.preventDefault()
  },
  removePreventDefault() {
    document.removeEventListener('click', dom.preventDefaults );
  },
  editable() {
    document.addEventListener('click', node.editable );
    document.addEventListener('mouseover', node.handleHover);
  },
  notEditable() {
    document.removeEventListener('click', node.editable );
    let editables = document.querySelectorAll('*[contenteditable=true]');
    editables.forEach(element => {
      node.notEditable(element);
      node.removeTextCursor(element);
    });
  },
  add(element) {
    document.body.appendChild(element);
  },
  prepend(element) {
    document.body.prepend(element);
  },
  createElement(html) {
    let template = document.createElement('template');
    html = html.trim();
    template.innerHTML = html;
    return template.content.firstChild;
  },
  handleRelativeDependencies() {
    let srcs = dom.html.querySelectorAll('*[src]');
    let hrefs = dom.html.querySelectorAll('*[href]');
    
    srcs.forEach(src => {
      src.src = src.src
    });
    
    hrefs.forEach(href => {
      href.href = href.href
    });
  }
}

export default dom;