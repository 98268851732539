import React, { Component } from 'react';
import TryNowButton from './TryNowButton';
import ChromeIcon from '../../assets/img/icon-chrome-white.svg';

class Header extends Component {
  constructor(props) {
    super(props);
    this.handleBuyButtonClick = this.handleBuyButtonClick.bind(this);
  }

  handleBuyButtonClick() {
    let pricingSection = document.querySelector('#pricing');
    window.scrollTo({ top: pricingSection.getBoundingClientRect().top - 100 });
  }

  render() {
    return (
      <header className="editm-site-header">
        <div className="editm-container">
          <div className="editm-site-header__title">
            <h1>
              Text update made easy
            </h1>
          </div>
          <div className="editm-site-header__subtitle">
            <p>
             Show them what you mean with ease
            </p>
          </div>
          <div className="editm-site-header__buttons">
            <a href="https://chrome.google.com/webstore/detail/edit-mode/gbbplijklonabgkeonkepkphjdjndhnb"
              target="_blank"
              className="editm-button editm-button--success">
              <img src={ChromeIcon}/>
              Add To Chrome For Free
            </a>
            <TryNowButton buttonTheme="primary" />
          </div>
        </div>
      </header>
    );
  }
}

export default Header;