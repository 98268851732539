import dom from './dom'

const cartFuelCredits = {
  wrap: null,
  init() {
    cartFuelCredits.wrap = cartFuelCredits.build();
  },
  build() {
    let wrap = `
          <div class="edit-mode__cartfuel">
              Powered by <a editm-element="true" target="_blank" href="https://www.cartfuel.io/?utm_source=editmode&utm_medium=belowbuttons&utm_campaign=free&utm_content=poweredbycartfuel"> Cartfuel.</a>
          </div>`;
    wrap = dom.createElement(wrap);

    return wrap;
  },
};

export default cartFuelCredits