import editModeWrapper from './editModeWrapper.js';
import editModeControlsWrapper from './editModeControlsWrapper.js';
import toggleModeButton from './toggleModeButton.js';
import closeButton from './closeButton.js';
import feedbackBox from './feedbackBox.js';
import finishEditionsButton from './finishEditionsButton.js';
import dom from './dom.js';
import './main.css';
import cartFuelCredits from './CartFuelCredits'

const app = {
  paused: false,
  initialized: false,
  visible: false,
  init() {
    app.play();
    app.visible = true;
    app.initialized = true;

    editModeWrapper.init();
    editModeControlsWrapper.init();
    cartFuelCredits.init();
    toggleModeButton.init();
    closeButton.init();
    feedbackBox.init();
    finishEditionsButton.init();
    dom.init();

    editModeControlsWrapper.addChild(feedbackBox.element);
    editModeControlsWrapper.addChild(toggleModeButton.button);
    editModeControlsWrapper.addChild(finishEditionsButton.buttonWrap);
    editModeControlsWrapper.addChild(closeButton.button);
    editModeWrapper.addChild(cartFuelCredits.wrap);
    editModeWrapper.prepend(editModeControlsWrapper.wrap);

    dom.add(editModeWrapper.wrap);
  },
  play() {
    app.paused = false;
    dom.editable();
  },
  pause() {
    dom.notEditable();
    app.paused = true;
  },
  toggleEditMode() {
    app.paused ? app.play() : app.pause();
  },
  close() {
    app.reset();
  },
  active() {
    app.visible = true;
    editModeWrapper.show();
  },
  reset() {
    app.pause();
    app.visible = false;
    app.initialized = false;

    editModeWrapper.reset();
  }
}

export default app;