import React, { Component } from 'react';
const axios = require('axios');

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      message: null
    }
  }

  postMessage() {
    let emailInput = document.querySelector('.jsInputEmail');
    let textInput = document.querySelector('.jsInputMessage');
    let button = document.querySelector('.jsSubmitContact');
    let email = emailInput.value;
    let message = textInput.value;

    emailInput.value = "";
    textInput.value = "";
    button.textContent = "Sending...";

    axios.post('https://formcarry.com/s/vbzyPFwtZeN', {
      email: email,
      message: message
    }).then((response) => {
      button.textContent = "Message sent!";
      button.classList.remove('button--success');
      button.classList.add('editm-button--success');

      setTimeout(() => {
        button.textContent = "Send";
        button.classList.add('editm-button--success');
        button.classList.remove('editm-button--success');
      }, 1000)
    }).catch((error) => {
      console.log(error);
    });
  }

  render() {
    return (
      <div className="editm-section contact">
        <div className="editm-container">
          <div className="editm-section__title">
            Talk to us
          </div>
          <div className="contact__form editm-form">
            <div className="input">
              <label htmlFor="email" className="input__label">Your email</label>
              <input
                placeholder="dale@carnegie.com"
                className="input__text jsInputEmail"
                type="text"
                name="email" />
            </div>
            <div className="input">
              <label htmlFor="message" className="input__label">And your message</label>
              <textarea
                rows="5"
                placeholder="Hi! [...]"
                className="input__textarea jsInputMessage"
                type="text"
                name="message"></textarea>
            </div>
            <div className="form-button">
              <button onClick={this.postMessage.bind(this)}
                className="editm-button editm-button--primary editm-button--primary jsSubmitContact">
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Form;