import dom from './dom.js';

const feedbackBox = {
  element: null,
  text: "Start editing!",
  init() {
    feedbackBox.element = feedbackBox.build();
  },
  build() {
    let box = `
      <div 
        editm-element="true"
        contenteditable="false"
        class="editm-feedback-box success show">
        ${feedbackBox.text}
      </div>
    `;
    
    box = dom.createElement(box)
    return box;
  },
  show() {
    feedbackBox.element.classList.toggle('show');
    setTimeout(() => {
      feedbackBox.element.classList.toggle('show');
    },200);
  },
  changeText(text) {
    feedbackBox.element.textContent = text;
  }
}

export default feedbackBox;