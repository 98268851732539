import React, { Fragment } from 'react';
import logo from '../../assets/img/logotipo.svg';
import { Link } from 'react-router-dom';

const Logo = (props) => {
  return (
    <Fragment>
      <Link to="/">
        <img src={logo} alt="logo" />
      </Link>
    </Fragment>
  )
}

export default Logo;