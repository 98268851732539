import React, { Component, Fragment } from 'react';
import AppNavbar from '../../components/Navbar/AppNavbar';
import editions from '../../helpers/editions.js';
import EditionBox from './EditionBox';
import NoEditionsMessage from './NoEditionsMessage';
import UsageLimitMessage from './UsageLimitMessage';
import EnterLicense from '../../components/EnterLicense';
import user from '../../helpers/user.js';
import authentication from '../../helpers/authentication';
import FeedbackButton from '../../components/FeedbackButton/FeedbackButton';

class Editions extends Component {
  constructor(props) {
    super(props);
    if(!authentication.userIsLogged()) {
      window.location.href="/login";
    }

    this.state = {
      editions: [],
      hasValidLicense: false,
      editionsAreLoaded: false,
    }
    this.getEditions = this.getEditions.bind(this);
    this.gotValidLicense = this.gotValidLicense.bind(this);
  }

  componentDidMount() {
    this.getEditions();
    this._ismounted = true;

    if(user.getLicense() != "undefined") {
      this.setState({
        hasValidLicense: true
      });
    }
  }

  componentWillUnmount() {
     this._ismounted = false;
  }

  async getEditions() {

    let editionsArray = await editions.list();

    this.setState({
      editions: editionsArray.map((item, index, array) => {
        return <EditionBox
          url={item.url}
          editionDate={ item.createdAt }
          title={ item.pageTitle }
          key={index}
          pageId={item.pageId} />
      }),
      editionsAreLoaded: true
    });

  }

  gotValidLicense() {
    this.setState({
      hasValidLicense: true
    });
  }

  render() {

    return (
      <Fragment>
        <AppNavbar />
        <section className="editm-section editm-editions">
          <div className="editm-container">
            <div className="editm-section__title">
              <h1>Your editions</h1>
            </div>
            <div className="editm-editions__container">
              {this.state.editions}
              
              {(this.state.editionsAreLoaded && this.state.editions.length === 0 && <NoEditionsMessage/>)}
              
              {(!this.state.hasValidLicense && <UsageLimitMessage/>)}

              {(!this.state.hasValidLicense && <EnterLicense  onLicenseValid={this.gotValidLicense}/>)}

              <FeedbackButton/>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

export default Editions;