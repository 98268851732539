import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar.js'
import Footer from '../../components/Footer/Footer.js';
import PricingSection from '../../components/PricingSection/PricingSection.js';

const Pricing = (props) => {
  return(
    <Fragment>
      <Navbar/>
      <PricingSection/>
      <Footer/>
    </Fragment>
  );
}

export default Pricing;