import React, { Component } from 'react';
import iconChrome from '../../assets/img/icon-chrome-white.svg';

const Payment = (props) => {
  return (
    <div className="editm-section editm-payment">
      <div className="editm-container">
        <div className="editm-section__title editm-payment__title">
          Free Forever
        </div>
        <div className="editm-payment__button-region">
          <a href="https://chrome.google.com/webstore/detail/edit-mode/gbbplijklonabgkeonkepkphjdjndhnb"
            target="_blank"
            className="editm-button editm-button--success editm-button--large">
            <img src={iconChrome} alt="icon chrome" />
            <span className="button__text">
              Add To Chrome For Free
            </span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Payment;