import React, { Component, Fragment } from 'react';
import Frame from 'react-frame-component';
import PageScript from './PageScript/index.js';
import EditmHeader from './EditmHeader.js';
const axios = require('axios');

class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: null
    }
  }

  removeEditModeWrapper() {
    const iframe = document.querySelector('.jsPageIframe') 
    const appWrap = iframe.contentDocument.querySelector('.jsEditmWrapper');
    if (!!appWrap) {
      appWrap.remove();
    }
  }
  
  componentDidMount() {
    const { match: { params } } = this.props;

    axios.get(`https://editmode-api.herokuapp.com/page/${params.pageId}`)
      .then(({ data }) => {
        this.setState({ page: data.webPage });
      })
      .then(() => {
        this.removeEditModeWrapper();
        PageScript.init();
      });
  }

  render() {
    return (
      <Fragment>
        <EditmHeader pageId={this.props.match.params.pageId}/>
        {/* <html dangerouslySetInnerHTML={{ __html: this.state.page }} ></html> */}
        <Frame width="100%" height="100%" className="jsPageIframe">
          <style dangerouslySetInnerHTML={{ __html: `
           .editm-edited-element {
                background: #fffd82e0 !important;
                color: #2d3047 !important;
            }
          `}}></style>
          <html dangerouslySetInnerHTML={{ __html: this.state.page }} ></html>
        </Frame>
      </Fragment>
    )
  }
}

export default Page;