import React from 'react';
// import FeedbackIcon from '../../assets/img/icon-feedback.svg';
// import FeedbackIcon from '../../assets/img/icon-feedback-2.svg';
import FeedbackIcon from '../../assets/img/icon-talk.svg';

const FeedbackButton = (props) => {
  return(
    <div className="feedback-button">
      <div className="feedback-button__inside">
        <a target="_blank"
        href="https://docs.google.com/forms/d/e/1FAIpQLSe7chYlexPOPzzrwoCPMcLqQWnXKCC0oNLWDM9eZbbBis0eDw/viewform">
          <img src={FeedbackIcon} alt="Icon"/>
          <span>
            Feedback
          </span>
        </a>
      </div>
    </div>
  )
}

export default FeedbackButton;