import React from 'react';
import video from '../../assets/video/video.mp4';
import poster from '../../assets/img/video-cover.png';

const AppDemonstration = (props) => {
  return (
    <div className="editm-section editm-app-demonstration">
      <div className="editm-container">
        <div className="editm-app-demonstration__video">
          <video loop={true} autoPlay muted playsinline poster={poster}>
            <source src={video} type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
}

export default AppDemonstration;