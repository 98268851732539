const jsSHA = require("jssha");
const axios = require('axios');

const authentication = {
  sha256(message) {
    var sha256 = new jsSHA('SHA-256', 'TEXT');
    sha256.update(message);
    var hash = sha256.getHash("HEX");

    return hash;
  },
  async getUser(user) {
    const response = await axios.get(`https://editmode-api.herokuapp.com/user/${user.email}`);
    return response.data;
  },
  checkIfPasswordMatch(user, userData) {
    return user.password === userData.password;
  },
  storeNewUser(user) {
    axios.post('https://editmode-api.herokuapp.com/user', {
      name: user.name,
      email: user.email,
      password: user.password
    }).then((response) => {
      console.log(response);
    }).catch((error) => {
      console.log(error);
    });
  },
  storeUserSession(user) {
    localStorage.setItem('editmLoggedUserEmail', user.email);
    localStorage.setItem('editmUserLicenseKey', user.licenseKey);
    localStorage.setItem('editmUserUsage', user.usage )
  },
  logout() {
    localStorage.removeItem('editmLoggedUserEmail');
    window.location.href = "/login"
  },
  userIsLogged() {
    return !!localStorage.getItem('editmLoggedUserEmail');
  }
}


export default authentication;