import React from 'react';
import { Link } from 'react-router-dom';

const AuthFooter = (props) => {
  return(
    <footer className="editm-auth-footer editm-section">
        <Link to="/privacy">Privacy Policy</Link>
    </footer>
  )
}

export default AuthFooter;