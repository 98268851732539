import user from './user.js';
const axios = require('axios');

const editions =  {
  async list() {
    let userEmail = user.getEmail();    
    let response = await axios.get(`https://editmode-api.herokuapp.com/editions/${ userEmail }`);
    return response.data;
  },
  async changeTitle(pageId, newTitle) {
    let response = await axios.post('https://editmode-api.herokuapp.com/edition', {
      pageId,
      pageTitle: newTitle
    });

    return response;
  }
}

export default editions;