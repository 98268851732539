import app from './app.js';
import toggleModeButton from './toggleModeButton.js';
import dom from './dom.js';
import editModeWrapper from './editModeWrapper.js';
import loader from './img/loading.gif';
import helpers from './helpers.js';

const finishEditionsButton = {
  button: null,
  buttonWrap: null,
  init() {
    finishEditionsButton.button = finishEditionsButton.build();
    finishEditionsButton.button.addEventListener('click', finishEditionsButton.handleClick);
    finishEditionsButton.buttonWrap = finishEditionsButton.buildWrap();
    finishEditionsButton.buttonWrap.appendChild(finishEditionsButton.button);
  },
  handleClick(e) {
    if (app.paused) {
      dialogBox.init();
      dialogBox.show();
    } else {
      app.pause();
      sendEditions.init();
      sendEditions.send();
    }

    toggleModeButton.rebuildButton();
  },
  buildWrap() {
    let wrap = `
      <div
      editm-element="true"
      contenteditable="false"
      class="editm-ext-button-wrap">
      </div>
    `;

    wrap = dom.createElement(wrap);
    return wrap;
  },
  build() {
    let button = `
      <button
      editm-element="true"
      contenteditable="false"
      class="editm-ext-button">
        Finish
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.9722 6.251C16.0052 5.713 14.7872 6.063 14.2522 7.028L10.5392 13.71L8.41424 11.585C7.63324 10.804 6.36724 10.804 5.58624 11.585C4.80524 12.366 4.80524 13.632 5.58624 14.413L9.58624 18.413C9.96424 18.792 10.4742 19 11.0002 19C11.0922 19 11.1852 18.994 11.2772 18.98C11.8982 18.893 12.4432 18.52 12.7482 17.971L17.7482 8.971C18.2852 8.005 17.9372 6.788 16.9722 6.251Z" fill="white"/>
        </svg>
      </button>
    `;

    button = dom.createElement(button);
    return button;
  }
}

const dialogBox = {
  element: null,
  hidden: true,
  init() {
    dialogBox.element = dialogBox.build();
    editModeWrapper.addChild(dialogBox.element);
  },
  build() {
    let box = `
      <div
      editm-element="true"
      contenteditable="false"
      class="editm-dialog-box hidden jsEditmDialogBox">

      </div>
    `;

    box = dom.createElement(box);
    return box;
  },
  show() {
    dialogBox.element.classList.remove('hidden');
    document.addEventListener('click', dialogBox.handleClick);
    dialogBox.hidden = false;
  },
  handleClick(e) {
    let finishButtonClicked = !!e.path.find(item => item === finishEditionsButton.button);
    let dialogBoxClicked = !!e.path.find(item => item === dialogBox.element);
    let canHide = finishButtonClicked || dialogBoxClicked;

    if (!canHide) {
      dialogBox.hide();
    }
  },
  hide() {
    dialogBox.element.classList.add('hidden');
    document.removeEventListener('click', dialogBox.handleClick);
    dialogBox.hidden = true;
  },
  showSavingLoader() {
    let loading = `
      <div class="editm-dialog-box__loading jsFinishEditionsLoading">
        <img src="${ loader}">
        <span>Saving</span>
      </div>    
    `;

    loading = dom.createElement(loading);
    dialogBox.element.innerHTML = "";
    dialogBox.element.appendChild(loading);
  },
  showLinkSender(link) {
    let element = `
      <div class="editm-dialog-box__send-editions">
        <div class="editm-dialog-box__send-editions-title">
          Send editions
        </div>
        <div class="editm-dialog-box__copy-link jsEditmCopyLink">
          

        </div>
      <div>
    `;

    element = dom.createElement(element);
    addCopyButton(element);
    addLink(element);
    dialogBox.element.innerHTML = "";
    dialogBox.element.appendChild(element);

    function addCopyButton(element) {
      copyButton.init();
      let copyWrap = element.querySelector('.jsEditmCopyLink');
      copyWrap.prepend(copyButton.button);
    }

    function addLink(element) {
      pageLink.init(link);
      let copyWrap = element.querySelector('.jsEditmCopyLink');
      copyWrap.appendChild(pageLink.element);
    }
  },
}

const pageLink = {
  element: null,
  link: null,
  init(link) {
    pageLink.link = link;
    pageLink.element = pageLink.build(link);
    pageLink.element.addEventListener('click', pageLink.handleClick);
  },
  handleClick() {
    window.open(
      pageLink.link,
      '_blank'
    );
  },
  build(link) {
    let element = `
      <span class="editm-dialog-box__copy-link-text jsSavedPageLink">
        <a target="_blank" 
        class="jsEditmEditedPageRoute" href="${link}">${link.replace(/https:\/\/www./, '')}</a>
      </span>
    `;

    element = dom.createElement(element);
    return element;
  }
}

const copyButton = {
  button: null,
  init() {
    copyButton.button = copyButton.build();
    copyButton.button.addEventListener('click', copyButton.handleClick);
  },
  handleClick() {
    let text = document.querySelector('.jsEditmEditedPageRoute').href;
    helpers.copyText(text);

    copyButton.button.classList.add('success');
    let buttonText = copyButton.button.querySelector('.jsEditmButtonText');
    buttonText.textContent = "Copied!";

    setTimeout(() => {
      copyButton.button.classList.remove('success');
      buttonText.textContent = "Copy link";
    }, 2000);
  },
  build() {
    let button = `
      <button 
        contenteditable="false"
        editm-element="true"
        class="editm-ext-button">
        <span class="editm-ext-button__text jsEditmButtonText">Copy link</span>
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.5151 6.05762C17.103 5.64554 16.436 5.64554 16.0239 6.05762L14.3746 7.70787L14.0795 7.41271C12.9601 6.29337 10.9975 6.29337 9.87813 7.41271L5.87997 11.4109C5.3203 11.9705 5.01172 12.7171 5.01172 13.5115C5.01172 14.306 5.3203 15.0525 5.87997 15.6122L6.17513 15.9074L4.52488 17.5576C4.1128 17.9697 4.1128 18.6367 4.52488 19.0488C4.73092 19.2548 5.00022 19.3574 5.27047 19.3574C5.54072 19.3574 5.81001 19.2548 6.01605 19.0488L7.6663 17.3985L7.96147 17.6937C8.52113 18.2534 9.26672 18.562 10.0621 18.562C10.8575 18.562 11.6031 18.2534 12.1628 17.6937L16.161 13.6955C16.7206 13.1359 17.0292 12.3903 17.0292 11.5949C17.0292 10.7995 16.7206 10.0539 16.161 9.49421L15.8658 9.19904L17.516 7.54879C17.9272 7.13671 17.9272 6.46971 17.5151 6.05762ZM14.6698 12.2044L10.6716 16.2025C10.5106 16.3635 10.2864 16.445 10.0621 16.445C9.83788 16.445 9.61363 16.3645 9.45263 16.2025L9.15747 15.9074L9.84938 15.2155C10.2615 14.8034 10.2615 14.1364 9.84938 13.7243C9.64334 13.5182 9.37405 13.4157 9.1038 13.4157C8.83355 13.4157 8.56426 13.5182 8.35822 13.7243L7.6663 14.4162L7.37113 14.121C7.21013 13.96 7.12101 13.7425 7.12101 13.5115C7.12101 13.2806 7.21013 13.063 7.37113 12.902L11.3693 8.90387C11.5303 8.74287 11.7469 8.65375 11.9788 8.65375C12.2107 8.65375 12.4273 8.74287 12.5883 8.90387L12.8835 9.19904L12.1915 9.89096C11.7795 10.303 11.7795 10.97 12.1915 11.3821C12.3976 11.5882 12.6669 11.6907 12.9371 11.6907C13.2074 11.6907 13.4767 11.5882 13.6827 11.3821L14.3746 10.6902L14.6698 10.9854C14.8308 11.1464 14.9199 11.3639 14.9199 11.5949C14.9199 11.8258 14.8308 12.0434 14.6698 12.2044Z" fill="white"/>
        </svg>
      </button>
    `;

    button = dom.createElement(button);
    return button;
  }
}

const sendEditions = {
  document: null,
  init() {
    sendEditions.convertDocumentToString();
    dialogBox.init();
    dialogBox.show();
    dialogBox.showSavingLoader();
  },
  convertDocumentToString() {
    sendEditions.document = dom.html.outerHTML;
  },
  minifyHTML() {

  },
  removeDoubleQuotes() {

  },
  send() {
    let pageId = new Date().getTime().toString();

    let url = window.location.href;

    let request = new XMLHttpRequest();
    request.open('POST', 'https://editmode-api.herokuapp.com/page/', true);
    // request.open('POST', 'http://localhost:3333/page', true);
    request.setRequestHeader('Content-Type', 'application/json');

    let data = JSON.stringify({
      webPage: sendEditions.document,
      pageId,
      url
    });

    request.onload = function (data) {
      // dialogBox.showLinkSender('http://localhost:3333/page/' + pageId);
      dialogBox.showLinkSender('https://www.editmode.io/page/' + pageId);
    }

    request.send(data);
  }
}

export default finishEditionsButton;