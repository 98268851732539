const helpers = {
  isEditModeLocation() {
    return window.location.hostname == "localhost" || window.location.hostname === "www.editmode.io";
  },
  copyText(text) {
    const el = document.createElement('textarea');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }
}

export default helpers;