import React from 'react';
import hamburguerIcon from '../../assets/img/hamburguer.svg';
import closeIcon from '../../assets/img/close.svg';
import Logo from './Logo';
import { Link } from 'react-router-dom';

class Navbar extends React.Component {
  constructor(props) {
    super(props);

    this.goToInstallSection = this.goToInstallSection.bind(this);
    // this.myRef = React.createRef();
  }
  openMenu() {
    let menuLinks = document.querySelector('.jsMenuLinks');
    menuLinks.classList.remove('hidden');
    menuLinks.classList.add('active');
  }

  closeMenu() {
    let menuLinks = document.querySelector('.jsMenuLinks');
    menuLinks.classList.add('hidden');
    menuLinks.classList.remove('active');
  }

  goToInstallSection(event) {
    event.preventDefault();
    this.closeMenu();
    let pricingSection = document.querySelector('#install');
    window.scrollTo({ top: pricingSection.getBoundingClientRect().top - 100 });
  }

  goToPricingSection(event) {
    event.preventDefault();
    this.closeMenu();
    let pricingSection = document.querySelector('#pricing');
    window.scrollTo({ top: pricingSection.getBoundingClientRect().top - 30 });
  }

  render() {
    return (
      <nav className="editm-navbar">
        <div className="editm-navbar__inside">
          <div className="editm-navbar__logo">
            <Logo />
          </div>
          <div className="menu">
            <div className="menu__links">
              <div className="menu__links-list">
                <div className="menu__link">
                  {/* <Link to="/login">Sign In</Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;