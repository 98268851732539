import React, { Component, Fragment } from 'react';
import  gumroadHelper from  '../../helpers/gumroad.js';
import  formHelpers from  '../../helpers/form.js';
import loadingGif from '../../assets/img/loading.gif';
import user from '../../helpers/user.js';

class EnterLicense extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputedLicense: '',
      loading: false,
      submitButtonText: "Submit"
    }

    this.handleLicenseSubmit = this.handleLicenseSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.validateInputedLicense = this.validateInputedLicense.bind(this);
  }

  handleInputChange(event) {
    let inputedLicense = event.target.value;
  
    this.setState({
      inputedLicense
    });
    
    formHelpers.removeInputError(event.target.closest('.input'));
  }

  async handleLicenseSubmit(e) {
    e.preventDefault();
    this.setState({
      loading: true
    });

    await this.validateInputedLicense();

    this.setState({
      loading: false,
    });

  }

  async validateInputedLicense() {
    let result = await gumroadHelper.licenseIsValid(this.state.inputedLicense);
    if(result) {
      let submitButton = document.querySelector('.jsInputButton');
      let licenseInput = document.querySelector('.jsInputLicense');
      submitButton.classList.remove('primary');
      submitButton.classList.add('success');
      
      this.setState({
        submitButtonText: "Success"
      });

      user.setLicense(this.state.inputedLicense);
      licenseInput.value = "";
      
      this.hideComponent();
      
    } else {
      formHelpers.setInputError(document.querySelector('.jsLicenseKeyWrap'),
      "Your license is wrong")
    }
  }
  
  hideComponent() {
    setTimeout(() => {
      this.props.onLicenseValid();
    },1000);
  }


  render() {
    return(
      <div className="editm-enter-license ">
        <form onSubmit={ this.handleLicenseSubmit } className="editm-form">
          <div className="input jsLicenseKeyWrap">
            <label htmlFor="license_key" className="input__label">Enter your license key</label>
            <div className="input__wrap">
              <input
                placeholder="OmyG5dPleDsByKGHsneuDQ=="
                className="input__text jsInputLicense"
                type="text"
                required
                onChange={this.handleInputChange}
                name="license_key" />
              <button type="submit"
              className="input__button jsInputButton primary">
                { (this.state.loading && <img src={ loadingGif }/>)}
                { (!this.state.loading && this.state.submitButtonText )}
              </button>
            </div>
            <p className="input__feedback jsInputFeedback"></p>
          </div>
        </form>
      </div>
    );
  }
}

export default EnterLicense;