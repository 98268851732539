import React, { Component, Fragment } from 'react';


class UsageLimitMessage extends Component {
  constructor(props) {
    super(props);  
  }

  render() {
    return (
      <div className="editm-message-box success">
        <div className="editm-message-box__text">
          You are limited to just one edition
          in the free mode.
          <a href="/#pricing">
            Buy now
          </a>
          your license
          to make more editions!
        </div>
      </div>
    );
  }
}

export default UsageLimitMessage;