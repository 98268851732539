import CopyAndToggle from './CopyAndToggle.js';

const editmClickCountHandler = {
  count: 0,
  target: null,
  handleSingleDoubleClick(event) {
    editmClickCountHandler.target = event.target;
    if (!editmClickCountHandler.count) setTimeout(editmClickCountHandler.TimerFcn, 300); // 400 ms click delay
    editmClickCountHandler.count += 1;
  },
  TimerFcn() {
    let target = editmClickCountHandler.target;
    if (editmClickCountHandler.count > 1) CopyAndToggle.handleDoubleClick(target);
    else CopyAndToggle.handleSingleClick(target);
    editmClickCountHandler.count = 0;
  },
}

export default editmClickCountHandler;