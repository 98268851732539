const axios = require('axios');

const gumroadHelper = {
  async licenseIsValid(licenseKey) {
    let response = await axios.post('https://cors-anywhere.herokuapp.com/https://api.gumroad.com/v2/licenses/verify', {
      product_permalink: 'editmode',
      license_key: licenseKey
    }).catch((error) => {
    });

    if(!!response) 
    return response.data.success && response.data.purchase.permalink === 'editmode';
    else
    return false;
  }
}

export default gumroadHelper;