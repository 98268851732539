import React, { Fragment, Component } from 'react';
const axios = require('axios');

class AuthIframe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: {}
    }

    this.fechData = this.fechData.bind(this);
  }

  componentDidMount() {
    this.fechData();
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  async fechData() {
    let userEmail = localStorage.getItem('editmLoggedUserEmail');

    const response = await axios.get(`https://editmode-api.herokuapp.com/user/${userEmail}`);

    this.setState({
      message: {
        userEmail: userEmail,
        userLicense: response.data.licenseKey,
        userUsage: response.data.editionsMade,
        messageType: "user_auth"
      }
    })
  }

  render() {

    if (this._isMounted) {
      return (
        <Fragment>
          {window.parent.postMessage(this.state.message, '*')}
        </Fragment>
      );
    } else {
      return (
        <Fragment></Fragment>
      )
    }
  }
}


export default AuthIframe;