import React from 'react';
import logo from './assets/img/logotipo.svg';
import './assets/css/App.css';
import Home from './pages/Home/index.js';
import Page from './pages/Page/index.js';
import Login from './pages/Login/index.js';
import SignUp from './pages/SignUp/index.js';
import Editions from './pages/Editions/index.js';
import AuthIframe from './pages/AuthIframe/index.js';
import { BrowserRouter as Router, Switch, Link, Route } from 'react-router-dom';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import Contact from './pages/Contact/Contact.js';
import Pricing from './pages/Pricing/index.js';

function App() {
  return (
    <div className="App">
      <Home />
    </div>
  );
}

function Root() {
  return (
    <Router>
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/page/:pageId' exact component={Page} />
        <Route path='/login' exact component={Login} />
        <Route path='/sign-up' exact component={SignUp} />
        <Route path='/editions' exact component={Editions} />
        <Route path='/authiframe' exact component={AuthIframe} />
        <Route path='/privacy' exact component={PrivacyPolicy} />
        <Route path='/contact' exact component={Contact} />
        <Route path='/pricing' exact component={Pricing} />
      </Switch>
    </Router>
  );
}

export default Root;
