import editmNode from './editmNode.js';
import editmClickCountHandler from './editmClickCountHandler.js';
import FeedbackBox from './FeedbackBox.js';

const CopyAndToggle = {
  init() {
    const iframe = document.querySelector('.jsPageIframe') 
    let editedElements = iframe.contentDocument.querySelectorAll('[edit-mode-new]');
    editedElements.forEach(element => {
      element.addEventListener('click', CopyAndToggle.handleClick);
    });
  },
  handleClick(event) {
    editmClickCountHandler.handleSingleDoubleClick(event);
  },
  handleDoubleClick(target) {
    if (editmNode.isShowingNewContent(target)) {
      editmNode.showOldContent(target);
      editmNode.removeEditedStyle(target);
      editmNode.removeShowNewContentAttribute(target);
    } else {
      editmNode.showNewContent(target);
      editmNode.addEditedStyle(target);
      editmNode.addShowNewContentAttribute(target);
    }
  },
  handleSingleClick(target) {
    const el = document.createElement('textarea');
    el.value = target.textContent;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    FeedbackBox.init("Copied!");
    FeedbackBox.show();
  },
}

export default CopyAndToggle;