import React, { Component } from 'react';
import iconMouse from '../../assets/img/icon-mouse.svg';
import app from './extension/app.js';

// app.init();

class TryNowButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonTheme: props.buttonTheme
    }
  }
  handleClick() {
    if(!app.initialized)
    app.init();
  }
  render() {
    
    let theme = this.state.buttonTheme;
    let classes = `editm-button editm-button--${theme}`;
    return(

      <button className={ classes } onClick={ this.handleClick.bind(this) }>
        <img src={ iconMouse } />
        <span>
          Try it on this page
        </span>
      </button>
    );
  }
}

export default TryNowButton;