import React, { Component } from 'react';
import Logo from '../../assets/img/logo.svg';
import iconFacebook from '../../assets/img/icon-social-fb.svg';
import iconLinkedin from '../../assets/img/icon-social-in.svg';
import iconTwitter from '../../assets/img/icon-social-twitter.svg';
import iconInstagram from '../../assets/img/icon-social-insta.svg';
import { Link } from 'react-router-dom';


const Footer = (props) => {
  return (
    <footer className="editm-section editm-site-footer">
      <div className="editm-container">
        <div className="editm-site-footer__items">
          {/* <Link to="/faq" className="editm-site-footer__item">
            FAQ
          </Link> */}
          <a href="https://twitter.com/editmodeapp" 
          className="editm-site-footer__item">
            Twitter
          </a>
          <Link to="/contact" className="editm-site-footer__item">
            Email us
          </Link>
          <Link to="/privacy" className="editm-site-footer__item">
            Privacy Policy
          </Link>
          <a href="https://www.websitepolicies.com/policies/view/j57dPODu" 
          className="editm-site-footer__item">
            Terms of Use
          </a>
          <span className="copyright" className="editm-site-footer__item">
            © 2019-2020 Edit Mode.
          </span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;