import React, { Component } from 'react';
import iconEdit from '../../assets/img/icon-edit.svg';
import editions from '../../helpers/editions.js';
import { Link } from 'react-router-dom';

class EditionBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editionDate: props.editionDate.match(/\d*-\d*-\d*/)[0].replace(/-/g, '/'),
      title: props.title,
      pageId: props.pageId,
      url: props.url.replace(/[\D]*\/\//g, '')
    }

    this.handleEditIconClick = this.handleEditIconClick.bind(this);
    this.handleEditableTitleClick = this.handleEditableTitleClick.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
  }

  componentDidMount() {
    if(!this.state.title) {
      this.setState({
        title: `Edition of ${this.state.editionDate} on ${this.state.url}`,
        // url: this.state.url.replace(/[\D]*\//g,'')
      })
    } 
  }

  handleEditIconClick(e) {
    e.preventDefault();

    let editionTitle = e.currentTarget.parentElement.querySelector('.jsEditionTitle');

    editionTitle.contentEditable = true;
    editionTitle.focus();

    editionTitle.addEventListener('click', this.handleEditableTitleClick);
  }

  handleEditableTitleClick(e) {
    e.preventDefault();
  }

  async handleTitleChange(e) {
    let contentIsNew = this.newContent != e.target.textContent;
    if(contentIsNew) {
      editions.changeTitle(this.state.pageId, e.target.textContent);
      this.newContent = e.target.textContent;
    }
  }

  render() {
    let pageLink = `/page/${this.state.pageId}`
    return (
      <Link  to={pageLink} className="editm-edition-box">
        <div onKeyUp={this.handleTitleChange}
        className="editm-edition-box__title jsEditionTitle">
          {this.state.title}
        </div>
        <div className="editm-edition-box__edit-title"
          onClick={this.handleEditIconClick}>
          <img src={iconEdit} alt="icon edit" />
        </div>
      </Link>
    );
  }
}

export default EditionBox;