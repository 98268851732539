import React from 'react';
import imgTutorial01 from '../../assets/img/tutorial-01.gif';
import imgTutorial02 from '../../assets/img/tutorial-02.gif';
import imgTutorial03 from '../../assets/img/tutorial-03.gif';
import imgTutorial04 from '../../assets/img/tutorial-04.gif';

const AppExplanation = (props) => {
  return (
    <div className="editm-app-explanation">
      <div className="editm-container">
        <h2 className="editm-app-explanation__title">
          How it works
        </h2>
        <div className="editm-app-explanation__text">
          <p>
            Edit Mode makes the texts of your whole 
            page editable, giving you a link to share 
            your editions for anyone.
          </p>
        </div>
        <div className="editm-app-explanation__items">
          <div className="editm-app-explanation__items-section">
            <h3 className="editm-app-explanation__items-title"> 
              For you
            </h3>
            <div className="editm-app-explanation__item">
              <img src={ imgTutorial01 }/>
              <p>
                Edit any text in your site at live
              </p>
            </div>
            <div className="editm-app-explanation__item">
              <img src={ imgTutorial02 }/>
              <p>
                Once finished, you get a link with all 
                your editions stored. Send it!
              </p>
            </div>
          </div>
          <div className="editm-app-explanation__items-section">
            <h3 className="editm-app-explanation__items-title"> 
              For who you send your link 
            </h3>
            <div className="editm-app-explanation__item">
              <img src={ imgTutorial03 }/>
              <p>
                Click to copy the new content
              </p>
            </div>
            <div className="editm-app-explanation__item">
              <img src={ imgTutorial04 }/>
              <p>
                Double click to toggle from new to previous content
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppExplanation;