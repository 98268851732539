import CopyAndToggle from './CopyAndToggle.js';
import editmDOM from './editmDOM.js';

const SavedPage = {
  init() {
    CopyAndToggle.init();
    editmDOM.preventDefaults();
  },
  removeEditModeWrapper() {

    let interval = setInterval(() => {
      const iframe = document.querySelector('.jsPageIframe') 
      const appWrap = iframe.contentDocument.querySelector('.jsEditmWrapper');
      if (!!appWrap) {
        appWrap.classList.add('hidden')
        clearInterval(interval);
      }
    }, 200);

  }
}

export default SavedPage;