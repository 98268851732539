import React from 'react';
import iconX from '../../assets/img/icon-x.svg';
import iconChecked from '../../assets/img/icon-checked.svg';

const PricingSection = (props) => {
  return (
    <div className="editm-section editm-pricing" id="pricing">
      <div className="editm-container">
        <div className="editm-section__title">
          Pricing
        </div>
        <div className="editm-pricing__boxes">
          <div className="editm-pricing__box">
            <div className="editm-pricing__box-inside">
              <div className="editm-pricing__box-title">
                Free
              </div>
              <div className="editm-pricing__box-list">
                <div className="editm-pricing__box-list-item">
                  <div className="editm-pricing__box-list-item-icon">
                    <img src={iconX} alt="icon" />
                  </div>
                  <div className="editm-pricing__box-list-item-text">
                    Unlimited editions*
                  </div>
                </div>
              </div>
              <div className="editm-pricing__box-bottom">
                <a href="https://chrome.google.com/webstore/detail/edit-mode/gbbplijklonabgkeonkepkphjdjndhnb"
                  target="_blank"
                  className="editm-button editm-button--primary editm-button--large">
                  Install
                </a>
              </div>
            </div> 
            <span className="editm-pricing__box-disclaimer">
            *Limited to 1 edition
            </span>
          </div>
          <div className="editm-pricing__box">
            <div className="editm-pricing__box-inside">
              <div className="editm-pricing__box-title">
                $15 per month
              </div>
              <div className="editm-pricing__box-list">
                <div className="editm-pricing__box-list-item">
                  <div className="editm-pricing__box-list-item-icon">
                    <img src={iconChecked} alt="icon" />
                  </div>
                  <div className="editm-pricing__box-list-item-text">
                    Unlimited editions
                  </div>
                </div>
              </div>
              <div className="editm-pricing__box-bottom">
                <a className="editm-button editm-button--primary editm-button--large gumroad-button"
                  href="https://gum.co/editmode?wanted=true">
                  Buy for $15
                </a>
              </div>
            </div>
          </div>
          <div className="editm-pricing__box">
            <div className="editm-pricing__box-inside">
              <div className="editm-pricing__box-title">
                $90 per year
              </div>
              <div className="editm-pricing__box-list">
                <div className="editm-pricing__box-list-item">
                  <div className="editm-pricing__box-list-item-icon">
                    <img src={iconChecked} alt="icon" />
                  </div>
                  <div className="editm-pricing__box-list-item-text">
                    Unlimited editions
                  </div>
                </div>
                <div className="editm-pricing__box-list-item">
                  <div className="editm-pricing__box-list-item-icon">
                    <img src={iconChecked} alt="icon" />
                  </div>
                  <div className="editm-pricing__box-list-item-text">
                    Save 50%
                  </div>
                </div>
              </div>
              <div className="editm-pricing__box-bottom">
                <a className="editm-button editm-button--primary editm-button--large gumroad-button"
                  href="https://gum.co/editmode?wanted=true&yearly=true">
                  Buy for $90
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PricingSection;