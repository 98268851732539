import React, { Component, Fragment } from 'react';
import Header from './Header.js';
import Navbar from '../../components/Navbar/Navbar.js'
import AppExplanation from './AppExplanation';
import AppDemonstration from './AppDemonstration.js';
import Payment from './Payment.js';
import Footer from '../../components/Footer/Footer.js';

class Home extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return(
      <Fragment>
        <Navbar/>
        <Header/>
        <AppDemonstration/>
        <AppExplanation/>
        <Payment/>
        <Footer/>
      </Fragment>
    );
  }
}

export default Home;