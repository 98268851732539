import dom from './dom.js';

const editModeControlsWrapper = {
  wrap: null,
  init() {
    editModeControlsWrapper.wrap = editModeControlsWrapper.build();
  },
  build() {
    let wrap = `
    <div 
    editm-element="true"
    class="edit-mode__controls jsEditmWrapper">
    </div>`;
    wrap = dom.createElement(wrap);
    return wrap;
  },
  addChild(element) {
    editModeControlsWrapper.wrap.appendChild(element);
  },
  prepend(element) {
    editModeControlsWrapper.wrap.prepend(element);
  },
  toggle() {
    editModeControlsWrapper.wrap.classList.toggle('hidden');
  },
  show() {
    if(editModeControlsWrapper.wrap.classList.contains('hidden')) {
      editModeControlsWrapper.wrap.classList.remove('hidden');
    }
  },
  hide() {
    if(!editModeControlsWrapper.wrap.classList.contains('hidden'))
    editModeControlsWrapper.wrap.classList.add('hidden');
  },
  reset() {
    editModeControlsWrapper.wrap.remove();
  }
}

export default editModeControlsWrapper;