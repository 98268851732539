import editmDOM from './editmDOM.js';

const FeedbackBox = {
  element: null,
  text: "",
  init(text) {
    FeedbackBox.text = text;
    FeedbackBox.element = FeedbackBox.build();
    editmDOM.add(FeedbackBox.element);
  },
  build() {
    let box = `
      <div 
        editm-element="true"
        contenteditable="false"
        class="editm-feedback-box--floating success show">
        ${FeedbackBox.text}
      </div>
    `;

    box = editmDOM.createElement(box)
    return box;
  },
  show() {
    FeedbackBox.element.classList.toggle('show');
    setTimeout(() => {
      FeedbackBox.element.classList.toggle('show');
    }, 200);
  },
  changeText(text) {
    FeedbackBox.element.textContent = text;
  }
}

export default FeedbackBox;