import editmClickCountHandler from './editmClickCountHandler.js';

const editmNode = {
  isEditModeElement(element) {
    return !!element.getAttribute('editm-element');
  },
  initialContentIsStored(target) {
    return !!target.getAttribute('edit-mode-prev');
  },
  addShowNewContentAttribute(target) {
    target.setAttribute('show-new-content', true)
  },
  removeShowNewContentAttribute(target) {
    target.removeAttribute('show-new-content')
  },
  handleClick(event) {
    editmClickCountHandler.handleSingleDoubleClick(event);
  },
  isShowingNewContent(target) {
    return !!target.getAttribute('show-new-content');
  },
  showOldContent(target) {
    target.textContent = target.getAttribute('edit-mode-prev');
  },
  showNewContent(target) {
    target.textContent = target.getAttribute('edit-mode-new');
  },
  addEditedStyle(element) {
    element.classList.add('editm-edited-element');
  },
  removeEditedStyle(element) {
    if(element.classList.contains('editm-edited-element'))
    element.classList.remove('editm-edited-element');
  },
  addTextCursor(element) {
    if(!element.classList.contains('editm-text-cursor')) 
    element.classList.add('editm-text-cursor');
  },
  removeTextCursor(element) {
    if(element.classList.contains('editm-text-cursor')) 
    element.classList.remove('editm-text-cursor');
  }
}

export default editmNode;