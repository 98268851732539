import React from 'react';
import hamburguerIcon from '../../assets/img/hamburguer.svg';
import closeIcon from '../../assets/img/close.svg';
import logo from '../../assets/img/logotipo.svg';
import authentication from '../../helpers/authentication';
import { Link } from 'react-router-dom';

class AppNavbar extends React.Component {
  constructor(props) {
    super(props);
  }

  openMenu() {
    let menuLinks = document.querySelector('.jsMenuLinks');
    menuLinks.classList.remove('hidden');
    menuLinks.classList.add('active');
  }

  closeMenu() {
    let menuLinks = document.querySelector('.jsMenuLinks');
    menuLinks.classList.add('hidden');
    menuLinks.classList.remove('active');
  }

  logout() {
    authentication.logout();
  }

  render() {
    return (
      <nav className="editm-navbar">
        <div className="editm-navbar__inside">
          <div className="editm-navbar__logo">
            <Link to="/editions">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div className="menu">
            <div className="menu__hamburguer">
              <img className="jsOpenMenu" onClick={this.openMenu}
                src={hamburguerIcon} alt="Icon to toggle menu" />
            </div>
            <div className="menu__links jsMenuLinks hidden">
              <div className="menu__links-header">
                <img className="menu__links-header-icon-close jsOpenMenu"
                  src={closeIcon} onClick={this.closeMenu}
                  alt="Icon to toggle menu" />
              </div>
              <div className="menu__links-list">
                <div className="menu__link">
                  <a href="" onClick={this.logout.bind(this)} >Sign out</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default AppNavbar;