import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar.js'
import Footer from '../../components/Footer/Footer.js';
import Form from './Form.js';

const Contact = (props) => {
  return(
    <Fragment>
      <Navbar/>
      <Form/>
      <Footer/>
    </Fragment>
  );
}

export default Contact;