import React from 'react';
import Logo from './Logo';

class OnlyLogoNavbar extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <nav className="editm-navbar">
        <div className="editm-navbar__inside">
          <div className="editm-navbar__logo">
            <Logo />
          </div>
        </div>
      </nav>
    );
  }
}

export default OnlyLogoNavbar;