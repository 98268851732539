import app from './app.js';
import dom from './dom.js';

const toggleModeButton = {
  button: null,
  init() {
    toggleModeButton.button = toggleModeButton.buildButton();
    toggleModeButton.button.addEventListener('click', toggleModeButton.handleClick);
  },
  handleClick() {
    app.toggleEditMode();
    toggleModeButton.buttonContent("");
    let toggledContent = toggleModeButton.getButtonContent();
    toggleModeButton.buttonContent(toggledContent);
  },
  buttonContent(content) {
    toggleModeButton.button.innerHTML = content;
  },
  buildButton() {
    let button = `
      <button 
        contenteditable="false"
        editm-element="true"
        class="editm-ext-button">
        ${toggleModeButton.getButtonContent()}
      </button>
    `;

    button = dom.createElement(button);

    return button;
  },
  getButtonContent() {
    if (app.paused) {
      return `
      Continue
      <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.396 13.433C6.037 10.859 10 7 10 7C10 7 6.037 3.141 3.396 0.567C3.033 0.218 2.543 0 2 0C0.896 0 0 0.896 0 2V12C0 13.104 0.896 14 2 14C2.543 14 3.033 13.782 3.396 13.433Z" fill="white"/>
      </svg>
      `;
    } else {
      return `
      Pause
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 6C6.896 6 6 6.896 6 8V16C6 17.104 6.896 18 8 18C9.104 18 10 17.104 10 16V8C10 6.896 9.104 6 8 6Z" fill="white"/>
        <path d="M15 6C13.896 6 13 6.896 13 8V16C13 17.104 13.896 18 15 18C16.104 18 17 17.104 17 16V8C17 6.896 16.104 6 15 6Z" fill="white"/>
      </svg>`;
    }
  },
  rebuildButton() {
    toggleModeButton.button.innerHTML = "";

    if (app.paused) {
      toggleModeButton.button.innerHTML = `
          Continue
          <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.396 13.433C6.037 10.859 10 7 10 7C10 7 6.037 3.141 3.396 0.567C3.033 0.218 2.543 0 2 0C0.896 0 0 0.896 0 2V12C0 13.104 0.896 14 2 14C2.543 14 3.033 13.782 3.396 13.433Z" fill="white"/>
          </svg>
          `;
    } else {
      toggleModeButton.button.innerHTML = `
          Pause
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 6C6.896 6 6 6.896 6 8V16C6 17.104 6.896 18 8 18C9.104 18 10 17.104 10 16V8C10 6.896 9.104 6 8 6Z" fill="white"/>
            <path d="M15 6C13.896 6 13 6.896 13 8V16C13 17.104 13.896 18 15 18C16.104 18 17 17.104 17 16V8C17 6.896 16.104 6 15 6Z" fill="white"/>
          </svg>`;
    }

  }
}

export default toggleModeButton;