import React, { Component, Fragment } from 'react';


class NoEditionsMessage extends Component {
  constructor(props) {
    super(props);  
  }

  render() {
    return (
      <div className="editm-message-box purple">
        <div className="editm-message-box__text">
          You have no editions yet.
          <a target="_blank" 
             href="https://chrome.google.com/webstore/detail/edit-mode/gbbplijklonabgkeonkepkphjdjndhnb">
            Install Edit Mode extension
          </a>
          and start editing!
        </div>
      </div>
    );
  }
}

export default NoEditionsMessage;