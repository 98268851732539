import dom from './dom.js';

const editModeWrapper = {
  wrap: null,
  init() {
    editModeWrapper.wrap = editModeWrapper.build();
  },
  build() {
    let wrap = `
    <div 
    editm-element="true"
    class="edit-mode jsEditmWrapper">
    </div>`;
    wrap = dom.createElement(wrap);
    return wrap;
  },
  addChild(element) {
    editModeWrapper.wrap.appendChild(element);
  },
  prepend(element) {
    editModeWrapper.wrap.prepend(element);
  },
  toggle() {
    editModeWrapper.wrap.classList.toggle('hidden');
  },
  show() {
    if(editModeWrapper.wrap.classList.contains('hidden')) 
    editModeWrapper.wrap.classList.remove('hidden');
  },
  hide() {
    if(!editModeWrapper.wrap.classList.contains('hidden'))
    editModeWrapper.wrap.classList.add('hidden');
  },
  reset() {
    editModeWrapper.wrap.remove();
  }
}

export default editModeWrapper;