import app from './app.js';
import dom from './dom.js';

const closeButton = {
  button: null,
  init() {
    closeButton.button = closeButton.build();
    closeButton.button.addEventListener('click', closeButton.handleClick);
  },
  handleClick() {
    app.close();
    dom.removePreventDefault();
  },
  build() {
    let button = `
      <button 
        editm-element="true"
        contenteditable="false"
        class="editm-ext-button">
        Close
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.414 6.58599C16.634 5.80499 15.366 5.80499 14.586 6.58599L12 9.17199L9.41399 6.58599C8.63399 5.80499 7.36599 5.80499 6.58599 6.58599C5.80499 7.36699 5.80499 8.63299 6.58599 9.41399L9.17099 12L6.58599 14.586C5.80499 15.367 5.80499 16.633 6.58599 17.414C6.97599 17.805 7.48799 18 7.99999 18C8.51199 18 9.02399 17.805 9.41399 17.414L12 14.828L14.586 17.414C14.976 17.805 15.488 18 16 18C16.512 18 17.024 17.805 17.414 17.414C18.195 16.633 18.195 15.367 17.414 14.586L14.829 12L17.414 9.41399C18.195 8.63299 18.195 7.36699 17.414 6.58599Z" fill="white"/>
        </svg>  
      </button>
      `;

    button = dom.createElement(button);

    return button;
  }
}

export default closeButton;