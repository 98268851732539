import editmNode from './editmNode.js';

const editmDOM = {
  html: null,
  init() {
    editmDOM.html = document.querySelector('html');
    editmDOM.handleRelativeDependencies();
    editmDOM.preventDefaults();
  },
  preventDefaults() {
    const iframe = document.querySelector('.jsPageIframe') 
    
    iframe.contentDocument.addEventListener('click', function (e) {
      if(!editmNode.isEditModeElement(e.target)) e.preventDefault();
    });
  },
  editable() {
    document.addEventListener('click', editmNode.editable);
  },
  notEditable() {
    document.removeEventListener('click', editmNode.editable);
    let editables = document.querySelectorAll('*[contenteditable=true]');
    editables.forEach(element => {
      editmNode.notEditable(element);
      editmNode.removeTextCursor(element);
    });
  },
  add(element) {
    document.body.appendChild(element);
  },
  prepend(element) {
    document.body.prepend(element);
  },
  createElement(html) {
    let template = document.createElement('template');
    html = html.trim();
    template.innerHTML = html;
    return template.content.firstChild;
  },
  handleRelativeDependencies() {
    let srcs = editmDOM.html.querySelectorAll('*[src]');
    let hrefs = editmDOM.html.querySelectorAll('*[href]');

    srcs.forEach(src => {
      src.src = src.src
    });

    hrefs.forEach(href => {
      href.href = href.href
    });
  }
}

export default editmDOM;