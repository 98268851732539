import app from './app.js';
import feedbackBox from './feedbackBox.js';

const node = {
  editable(e) {
    if(!node.isEditModeElement(e.target)) e.preventDefault();
    
    let target = e.target;
    if(node.hasText(target) && !node.isEditModeElement(target)) {
      if(target.classList.contains('editm-hovered')) target.classList.remove('editm-hovered');
      target.classList.add('editm-content-editable');      
      node.storeInitialContent(target);
      target.contentEditable = true;
      node.addTextCursor(target);
      target.focus();
      target.addEventListener('input', node.handleTextChange );
    }
  },
  handleHover(e) {
    let hoveredElement = e.target;
    if (node.hasText(hoveredElement) && !node.isEditModeElement(hoveredElement) && !app.paused) {
      hoveredElement.classList.add('editm-hovered');
      hoveredElement.addEventListener('mouseout', function (e) {
        e.target.classList.remove('editm-hovered');
      });
    }
  },
  isEditModeElement(element) {
    return !!element.getAttribute('editm-element');
  },
  notEditable(element) {
    element.contentEditable = false;
  },
  initialContentIsStored(target) {
    return !!target.getAttribute('edit-mode-prev');
  },
  null() {
    
  },
  storeInitialContent(target) {
    if(!node.initialContentIsStored(target))
    target.setAttribute('edit-mode-prev', target.textContent );    
  },
  storeNewContent(target) {
    target.setAttribute('edit-mode-new', target.textContent );
  },
  hasText(element) {
    let hasOrNot = !!Array.from(element.childNodes).find(node => {
      return node.nodeName === "#text" && !!node.textContent.trim().length;
    });
    
    return hasOrNot;
  },
  handleTextChange(event) {
    let target = event.target;
    let prevContent = target.getAttribute('edit-mode-prev');
    let actualContent = target.textContent;
    
    prevContent === actualContent ? node.removeEditedStyle(target) : node.addEditedStyle(target);
    
    node.storeNewContent(target);
    node.addShowNewContentAttribute(target);
    target.addEventListener('click', node.handleClick );
  },
  addShowNewContentAttribute(target) {
    target.setAttribute('show-new-content', true)
  },
  removeShowNewContentAttribute(target) {
    target.removeAttribute('show-new-content')
  },
  handleClick(event) {
    clickCountHandler.handleSingleDoubleClick(event);
  },
  isShowingNewContent(target) {
    return !!target.getAttribute('show-new-content');
  },
  showOldContent(target) {
    target.textContent = target.getAttribute('edit-mode-prev');
  },
  showNewContent(target) {
    target.textContent = target.getAttribute('edit-mode-new');
  },
  handleDoubleClick(target) {
    if(app.paused) {
      if(node.isShowingNewContent(target)) {
        node.showOldContent(target);
        node.removeEditedStyle(target);
        node.removeShowNewContentAttribute(target);
      } else {
        node.showNewContent(target);
        node.addEditedStyle(target);
        node.addShowNewContentAttribute(target);
      }
    } 
  },
  handleSingleClick(target) {
    if(app.paused) {
      const el = document.createElement('textarea');
      el.value = target.textContent;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      
      feedbackBox.changeText('Copied!');
      feedbackBox.show();
      
    } 
  },
  addEditedStyle(element) {
    element.classList.add('editm-edited-element');
  },
  removeEditedStyle(element) {
    if(element.classList.contains('editm-edited-element')) 
    element.classList.remove('editm-edited-element');
  },
  addTextCursor(element) {
    if(!element.classList.contains('editm-text-cursor'))
    element.classList.add('editm-text-cursor');
  },
  removeTextCursor(element) {
    if(element.classList.contains('editm-text-cursor')) 
    element.classList.remove('editm-text-cursor');
  }
}


const clickCountHandler = {
  count: 0,
  target: null,
  handleSingleDoubleClick(event) {
    clickCountHandler.target = event.target;
    if(!clickCountHandler.count) setTimeout(clickCountHandler.TimerFcn, 300); // 400 ms click delay
    clickCountHandler.count += 1;
  },
  TimerFcn() {
    let target = clickCountHandler.target;
    if(clickCountHandler.count > 1) node.handleDoubleClick(target);
    else node.handleSingleClick(target);
    clickCountHandler.count = 0;
  },
}

export default node;