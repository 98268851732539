import React, { Component, Fragment } from 'react';
import OnlyLogoNavbar from '../../components/Navbar/OnlyLogoNavbar';
import autentication from '../../helpers/authentication';
import formHelpers from '../../helpers/form.js';
import loadingGif from '../../assets/img/loading.gif';
import { Link } from 'react-router-dom';
import AuthFooter from '../../components/AuthFooter/AuthFooter.js';

class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      password: '',
      loading: false
    }

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  inputToggle() {
    let iconWrap = document.querySelector('.jsInputIcon');
    let inputPassword = document.querySelector('.jsInputPassword');

    if (iconWrap.classList.contains('eye-blocked')) {
      iconWrap.classList.remove('eye-blocked');
      iconWrap.classList.add('eye');
      inputPassword.type = "text";
    } else {
      inputPassword.type = "password";
      iconWrap.classList.add('eye-blocked');
      iconWrap.classList.remove('eye');
    }
  }

  handleEmailChange(event) {
    let email = event.target.value;

    this.setState({
      email
    });

    formHelpers.removeInputError(event.target.closest('.input'));
  }

  handlePasswordChange(event) {
    let password = event.target.value;
    password = autentication.sha256(password);

    this.setState({
      password
    });

    formHelpers.removeInputError(event.target.closest('.input'));
  }

  handleNameChange(event) {
    let name = event.target.value;

    this.setState({
      name
    });
  }

  async handleFormSubmit(event) {
    event.preventDefault();
    this.setState({ loading: true });

    const user = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password
    }

    let userData = await autentication.getUser(user);
    let userExists = !!userData;

    if (userExists) {
      let emailInput = document.querySelector('.jsWrapEmail');
      formHelpers.setInputError(emailInput, "This account already exists.");
    } else {
      autentication.storeNewUser(user);
      autentication.storeUserSession(user);
      window.location.href = "/editions";
    }

    this.setState({ loading: false });
  }

  render() {
    return (
      <Fragment>
        <OnlyLogoNavbar />
        <section className="editm-section editm-autentication">
          <div className="editm-container">
            <div className="editm-section__title">
              <h2>Sign Up</h2>
            </div>
            <form onSubmit={this.handleFormSubmit} className="editm-form">
              <div className="input jsWrapName">
                <label htmlFor="name" className="input__label">Name</label>
                <input
                  placeholder="Dale Carnegie"
                  className="input__text jsInputName"
                  type="text"
                  required
                  onChange={this.handleNameChange}
                  name="name" />
                <p className="input__feedback jsInputFeedback"></p>
              </div>
              <div className="input jsWrapEmail">
                <label htmlFor="email" className="input__label">Email</label>
                <input
                  placeholder="dale@carnegie.com"
                  className="input__text jsInputEmail"
                  type="email"
                  required
                  onChange={this.handleEmailChange}
                  name="email" />
                <p className="input__feedback jsInputFeedback"></p>
              </div>
              <div className="input jsWrapPassword">
                <label htmlFor="password" className="input__label">Password</label>
                <div className="input__wrap error">
                  <input
                    placeholder="Like *******"
                    className="input__text jsInputPassword"
                    type="password"
                    required
                    onChange={this.handlePasswordChange}
                    name="password" />
                  <div className="input__icon eye-blocked jsInputIcon"
                    onClick={this.inputToggle.bind(this)}>
                  </div>
                </div>
                <p className="input__feedback jsInputFeedback"></p>
              </div>
              <div className="checkbox-input">
                <input type="checkbox" id="keep_contected" name="keep_contected" />
                <label htmlFor="keep_conected">Keep me conected</label>
              </div>
              <div className="form-button">
                {(this.state.loading ? <LoadingButton /> : <NormalButton />)}
              </div>
            </form>

            <div className="editm-autentication__bottom">
              <Link to="/login">Login</Link>
            </div>
          </div>
        </section>
        <AuthFooter/>
      </Fragment>
    );
  }
}

const NormalButton = (props) => {
  return (
    <button
      className="editm-button editm-button--primary editm-button--primary jsEnterButton">
      Create Account
    </button>
  );
}

const LoadingButton = (props) => {
  return (
    <button disabled
      className="editm-button editm-button--primary editm-button--primary jsEnterButton">
      <img src={loadingGif} />
    </button>
  );
}
export default SignUp;