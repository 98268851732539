const formHelpers = {
  setInputError(input, message) {
    input.classList.add('error');
    formHelpers.setErrorMessage(input, message);
  },
  setErrorMessage(input, message) {
    let inputFeedback = input.querySelector('.jsInputFeedback');
    inputFeedback.textContent = message;
  },
  removeInputError(input) {
    input.classList.remove('error');
  }
}

export default formHelpers;